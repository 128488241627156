<template>
  <div class="content block-el">
    <form @submit.prevent="update">
      <template v-if="fetched">
        <PageHeader :title="customer.name" />
        <div class="page-content container-fluid">
          <div class="row">
            <div class="col-xl-3 col-lg-4">
              <card no-header>
                <template #body>
                  <div class="profile-card text-center">
                    <div
                      class="thumb-xl member-thumb m-b-10 center-block"
                    ></div>
                    <div class="">
                      <h5 class="m-b-5">{{ customer.name }}</h5>
                      <p class="text-muted">{{ customer.company_name }}</p>
                      <p>
                        <span
                          v-if="customer.status === 'active'"
                          class="badge badge-success"
                          >{{ $t('app.active') }}</span
                        >
                        <span
                          v-else-if="customer.status === 'inactive'"
                          class="badge badge-secondary"
                          >{{ $t('app.inactive') }}</span
                        >
                        <span
                          v-else-if="customer.status === 'suspended'"
                          class="badge badge-danger"
                          >{{ $t('app.suspended') }}</span
                        >
                      </p>
                    </div>
                    <template v-if="customer.whitelabel_id">
                      <button
                        v-if="customer.status == 'active'"
                        type="button"
                        class="btn btn-primary btn-rounded text-white"
                        @click="suspend"
                      >
                        <i class="fas fa-user-slash"></i>
                      </button>
                      <button
                        v-else-if="customer.status == 'suspended'"
                        type="button"
                        class="btn btn-primary btn-rounded text-white"
                        @click="activate"
                      >
                        <i class="fas fa-user-check"></i>
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        class="btn btn-accent btn-rounded text-white"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </template>
                    <div class="m-t-40">
                      <h5 class="m-b-5">Responsaveis</h5>
                    </div>
                    <ul
                      v-for="owner in form.owners"
                      :key="owner.id"
                      class="list-reset text-left m-t-10"
                    >
                      <li class="text-muted">
                        <strong>{{ $tc('app.name') }}:</strong>
                        <span class="m-l-15">{{ owner.name }}</span>
                      </li>
                      <li class="text-muted">
                        <strong>{{ $t('app.email') }}:</strong>
                        <span class="m-l-15">{{ owner.email }}</span>
                      </li>
                      <li class="text-muted">
                        <strong>{{ $t('app.mobile_number') }}:</strong
                        ><span class="m-l-15">{{ owner.mobile_number }}</span>
                      </li>
                    </ul>
                  </div>
                </template>
                <hr />
                <ul class="nav sub-nav v-sub-nav flex-column p-b-30">
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      :to="{
                        name: 'CustomerInvoices',
                        params: { customerId: $route.params.customerId },
                      }"
                      ><i
                        class="
                          icon
                          dripicons-card
                          font-size-22
                          v-align-middle
                          p-r-15 p-t-5
                        "
                      ></i
                      >{{ $tc('app.invoice', 2) }}</router-link
                    >
                  </li>
                </ul>
              </card>
            </div>
            <div class="col-xl-9 col-lg-8">
              <card tabs>
                <template #header>
                  <ul class="nav nav-tabs primary-tabs p-l-30 m-0">
                    <li class="nav-item" role="presentation">
                      <router-link
                        :to="{ name: 'CustomerEdit' }"
                        class="nav-link"
                        replace
                        >{{ $t('app.about') }}</router-link
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link
                        :to="{ name: 'CustomerShortCodes' }"
                        class="nav-link"
                        replace
                        >Short Codes</router-link
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link
                        :to="{ name: 'CustomerPlans' }"
                        class="nav-link"
                        replace
                        >{{ $tc('app.plan', 2) }}</router-link
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link
                        :to="{ name: 'CustomerProducts' }"
                        class="nav-link"
                        replace
                        >{{ $tc('app.product', 2) }}</router-link
                      >
                    </li>
                  </ul>
                </template>
                <template #body>
                  <div class="tab-content">
                    <router-view :customer="customer" @update="customer = $event"/>
                  </div>
                </template>
              </card>
            </div>
          </div>
          <!--<div class="row">
            <div class="col-12">
              <div class="card card-tabs">
                <div class="card-header p-0 no-border">
                  <ul class="nav nav-tabs nav-fill primary-tabs">
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        v-tab="'account'"
                        class="nav-link active"
                        data-toggle="tab"
                        aria-expanded="true"
                        >{{ $tc('generic-str.acc', 1) }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        v-tab="'information'"
                        class="nav-link"
                        data-toggle="tab"
                        aria-expanded="true"
                        >{{ $t('generic-str.menu.infos') }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        v-tab="'short-codes'"
                        class="nav-link"
                        data-toggle="tab"
                        aria-expanded="true"
                        >Short Codes</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        v-tab="'plans'"
                        class="nav-link"
                        data-toggle="tab"
                        aria-expanded="true"
                        >{{ $tc('app.plan', 2) }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        v-tab="'products'"
                        class="nav-link"
                        data-toggle="tab"
                        aria-expanded="true"
                        >{{ $tc('generic-str.lbl-product', 2) }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                  <div class="tab-pane active" id="account">
                    <div class="card-body">
                      <h5 class="card-title">{{ $t('properties.company') }}</h5>
                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('adm.create.comp-name')
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('adm.create.comp-name')"
                            v-model="form.name"
                          />
                        </div>
                        <div class="form-group col-2">
                          <label for="name">CNPJ</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="CNPJ"
                            v-model="form.tax_id"
                            v-mask="'##.###.###/####-##'"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-6">
                          <label for="name">{{
                            $t('adm.create.corporate-name')
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('adm.create.corporate-name')"
                            v-model="form.company_name"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-6">
                          <label for="name">{{
                            $t('adm.create.billing-email')
                          }}</label>
                          <input
                            type="email"
                            class="form-control"
                            :placeholder="$t('adm.create.billing-email')"
                            v-model="form.billing_email"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label class="control-label text-left">{{
                            $t('generic-str.menu.anti-fraud')
                          }}</label>
                          <select
                            class="form-control"
                            v-model="form.antifraud"
                            required
                          >
                            <option selected value="open">
                              {{ $t('generic-str.status.lbl-open') }}
                            </option>
                            <option value="moderate">
                              {{ $t('generic-str.status.lbl-moderate') }}
                            </option>
                            <option value="strict">
                              {{ $t('generic-str.status.lbl-strict') }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="control-label text-left">Status</label>
                          <select class="form-control" v-model="form.status">
                            <option selected value="active">
                              {{ $t('generic-str.status.lbl-active') }}
                            </option>
                            <option value="suspended">
                              {{ $t('generic-str.status.lbl-suspended') }}
                            </option>
                            <option value="closed">
                              {{ $t('generic-str.status.lbl-closed') }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <h5 class="card-title">
                        {{ $t('generic-str.contract') }}
                      </h5>
                      <div class="form-row">
                        <div class="form-group col-md-3">
                          <label for="name">{{ $t('generic-str.type') }}</label>
                          <select class="form-control" v-model="form.type">
                            <option selected value="trial">
                              {{ $t('acc-settings.trial') }}
                            </option>
                            <option value="prepaid">
                              {{ $t('adm.customer.assign.prepaid') }}
                            </option>
                            <option value="weekly">
                              {{ $t('adm.customer.assign.weekly') }}
                            </option>
                            <option value="biweekly">
                              {{ $t('adm.customer.assign.biweekly') }}
                            </option>
                            <option value="monthly">
                              {{ $t('adm.customer.assign.monthly') }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="name">{{
                            $t('generic-str.funds')
                          }}</label>
                          <input
                            v-model="form.balance"
                            v-money="money"
                            class="form-control"
                            style="text-align: right"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="name">{{
                            $t('generic-str.expiration')
                          }}</label>
                          <input
                            v-model="form.due_date"
                            type="number"
                            class="form-control"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div
                          v-if="salesExecutives.length"
                          class="form-group col-md-4"
                        >
                          <label for="name">{{
                            $t('home.invite-card.select.sale-executive')
                          }}</label>
                          <select-input
                            v-if="
                              $store.state.account.role == 'owner' ||
                              form.sales_id
                            "
                            label="name"
                            :reduce="(sales) => sales.id"
                            v-model="form.sales_id"
                            :options="salesExecutives"
                            :clearable="true"
                            :disabled="$store.state.account.role != 'owner'"
                          />
                          <button
                            v-else
                            class="btn btn-success form-control"
                            @click="join"
                          >
                            {{ $t('generic-str.join-2') }}
                          </button>
                        </div>
                      </div>
                      <span v-for="owner in form.owners" :key="owner.id">
                        <h5 class="card-title">
                          {{ $t('generic-str.responsible') }}
                        </h5>
                        <div class="form-row">
                          <div class="form-group col-md-3">
                            <label for="name">E-Mail</label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="email"
                              v-model="owner.email"
                              disabled
                            />
                          </div>
                          <div class="form-group col-4">
                            <label for="name">{{
                              $tc('generic-str.name', 1)
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="$tc('generic-str.name', 1)"
                              v-model="owner.name"
                              disabled
                            />
                          </div>
                          <div class="form-group col-5">
                            <label for="name">{{
                              $t('generic-str.lbl-cellphone')
                            }}</label>
                            <tel-input
                              ref="mobile_number"
                              v-model="owner.mobile_number"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="tab-pane" id="information">
                    <div class="card-body">
                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('generic-str.zipcode')
                          }}</label>
                          <input
                            type="tel"
                            class="form-control"
                            :placeholder="$t('generic-str.zipcode')"
                            v-model="form.postal_code"
                            v-mask="'#####-###'"
                            :required="form.type != 'trial'"
                            @input="searchAddress"
                          />
                          <div
                            v-if="searching"
                            class="preloader pl-xxs pls-primary loading-cep"
                          >
                            <svg class="pl-circular" viewBox="25 25 50 50">
                              <circle class="plc-path" cx="50" cy="50" r="20" />
                            </svg>
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('billing.prefs.lbl-address')
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('billing.prefs.lbl-address')"
                            v-model="form.street"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $tc('generic-str.lbl-number', 1)
                          }}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$tc('generic-str.lbl-number', 1)"
                            v-model="form.number"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('generic-str.complement')
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('generic-str.complement')"
                            v-model="form.complement"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('generic-str.neighborhood')
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('generic-str.neighborhood')"
                            v-model="form.district"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{ $t('generic-str.city') }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('generic-str.city')"
                            v-model="form.city"
                            :required="form.type != 'trial'"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="name">{{
                            $t('generic-str.state')
                          }}</label>
                          <ProvinceSelect
                            :province="form.province"
                            :required="form.type != 'trial'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="short-codes">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <router-link
                            :to="`/admin/customers/${$route.params.customerId}/short-codes/create`"
                            class="btn btn-success btn-floating"
                          >
                            <i
                              class="
                                fas
                                fa-plus-circle
                                text-white
                                font-size-22
                                v-align-text-bottom
                              "
                            ></i>
                          </router-link>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>{{ $t('adm.customer.associated-in') }}</th>
                              <th>{{ $tc('generic-str.lbl-number', 1) }}</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="shortCode in shortCodes"
                              :key="shortCode.id"
                            >
                              <td>
                                {{
                                  shortCode.created_at
                                    | date('DD/MM/YYYY HH:mm:ss')
                                }}
                              </td>
                              <td>{{ shortCode.name }}</td>
                              <td class="action">
                                <div class="btn-group">
                                  <router-link
                                    :to="`/admin/customers/${$route.params.customerId}/short-codes/${shortCode.id}`"
                                    class="btn btn-primary"
                                  >
                                    <span class="icon dripicons-pencil"></span>
                                  </router-link>
                                  <button
                                    @click="unsign(shortCode.id)"
                                    type="button"
                                    class="btn btn-danger"
                                  >
                                    <span class="icon dripicons-trash"></span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="plans">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <router-link
                            :to="{ name: 'AttachPlan' }"
                            class="btn btn-success btn-floating"
                          >
                            <i
                              class="
                                fas
                                fa-plus-circle
                                text-white
                                font-size-22
                                v-align-text-bottom
                              "
                            ></i>
                          </router-link>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <data-table
                            :columns="[
                              {
                                key: 'service',
                                class: 'badge badge-primary',
                                label: 'product',
                              },
                              'name',
                              {
                                key: 'pivot.pricing.fee',
                                label: 'price',
                                filters: ['currency'],
                              },
                            ]"
                            :data="plans"
                          >
                            <template #actions="item">
                              <dropdown>
                                <template v-slot:text>
                                  <i class="fas fa-ellipsis-h"></i>
                                </template>
                                <template v-slot:items>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    @click="destroyPlan(item)"
                                  >
                                    {{ $t('app.cancel') }}
                                  </a>
                                </template>
                              </dropdown>
                            </template>
                          </data-table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="products">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <router-link
                            :to="`/admin/customers/${$route.params.customerId}/products/create`"
                            class="btn btn-success btn-floating float-right"
                          >
                            <i
                              class="
                                fas
                                fa-plus-circle
                                text-white
                                font-size-22
                                v-align-text-bottom
                              "
                            ></i>
                          </router-link>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>{{ $t('adm.customer.associated-in') }}</th>
                              <th>{{ $tc('generic-str.lbl-product', 1) }}</th>
                              <th>
                                {{ $tc('adm.customer.assign.credit', 2) }}
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="product in form.products"
                              :key="product.id"
                            >
                              <td>
                                {{
                                  product.created_at
                                    | date('DD/MM/YYYY HH:mm:ss')
                                }}
                              </td>
                              <td>{{ product.name }}</td>
                              <td>
                                {{
                                  product.plan.credits !== null
                                    ? product.plan.credits
                                    : $t('adm.customer.assign.unlimited')
                                }}
                              </td>
                              <td class="action">
                                <div class="btn-group">
                                  <router-link
                                    :to="`/admin/customers/${$route.params.customerId}/products/${product.id}`"
                                    class="btn btn-primary"
                                  >
                                    <span class="icon dripicons-pencil"></span>
                                  </router-link>
                                  <button
                                    @click="unsign(product.id)"
                                    type="button"
                                    class="btn btn-danger"
                                  >
                                    <span class="icon dripicons-trash"></span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </template>

      <div v-else class="static qt-block-ui" style="padding: 120px" />
    </form>
    <!-- <modal ref="plan-modal" :title="$t('generic-str.new-plan')" @submit="store">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('name') }}
        </label>
        <div class="col-sm-10">
          <input type="text" class="form-control" v-model="form.name" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('type') }}
        </label>
        <div class="col-sm-10">
          <select class="form-control" v-model="form.product" required>
            <option value="sms" selected>SMS</option>
            <option value="email">E-Mail</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('credits') }}
        </label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            v-model="form.quantity"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('price') }}
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            v-model="form.price"
            v-money="money"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('overage_price') }}
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            v-model="form.overage_price"
            v-money="money"
            required
          />
        </div>
      </div>
    </modal> -->
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Swal from 'sweetalert2';
import Tab from '@/directives/Tab';
import Card from '@/components/Card';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import UserService from '@/services/user.service';
import SmsService from '@/services/admin/sms.service';
import { mask } from 'vue-the-mask';

export default {
  name: 'ShowCustomer',
  components: {
    Card,
    PageHeader,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      updating: false,
      salesExecutives: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      customer: {},
      shortCodes: [],
      plans: [],
      form: {},
      searching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      CustomerService.getCustomer(this.$route.params.customerId).then(
        (customer) => {
          this.form = customer;
          this.customer = customer;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchSalesExecutives() {
      UserService.getUsers({
        role: 'sales',
      }).then(
        (salesExecutives) => {
          this.salesExecutives = salesExecutives;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    suspend() {
      this.fetched = false;
      this.$store
        .dispatch('updateCustomer', {
          customerId: this.$route.params.customerId,
          input: {
            status: 'suspended',
          },
        })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.updated'),
              type: 'success',
            });
            this.fetch();
          },
          (error) => {
            this.fetched = true;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        );
    },
    activate() {
      this.fetched = false;
      this.$store
        .dispatch('updateCustomer', {
          customerId: this.$route.params.customerId,
          input: {
            status: 'active',
          },
        })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.updated'),
              type: 'success',
            });
            this.fetch();
          },
          (error) => {
            this.fetched = true;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  border-bottom: solid 1px #353a40;
  color: #353a40;
}
</style>
